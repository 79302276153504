<template>
  <div class="main-header">
    <a href="https://www.karavantrailers.com" target="_blank" rel="noopener noreferrer">
      <svg viewBox="0 0 241.38 26.89" xmlns="http://www.w3.org/2000/svg" aria-label="Karavan Trailers Logo">
        <path d="M21.17 11.52L32.5 0h12.68l-12.8 12.38.86 14.5H19.78l.08-11.06H17.4L12.52 26.9H0L11.9 0h12.52l-5.13 11.52z" data-name="Path 421"/>
        <path d="M106.35 15.94a3.91 3.91 0 0 1-2.14 1.64 11.36 11.36 0 0 1-4.43.74l2.63 8.57H89.77l-2.14-8.57h-1.6l-3.77 8.57H69.99L81.89 0h23.27c2.87 0 4.84.33 5.86.94a1.84 1.84 0 0 1 .87 2.54zM92.07 4.71l-4.1 9.27h5.99c.78 0 1.27-.2 1.43-.58l3.62-8.15c.16-.37-.17-.58-.95-.58h-5.99z" data-name="Path 422"/>
        <path d="M167.6 0h12.43L156.8 26.89h-12.76L144.62 0h12.43l-.41 3.44-2.01 13.9 10.42-13.98z" data-name="Path 423"/>
        <path d="M210.85 0h11.7l1.47 10.78L228.78 0h12.6l-11.9 26.89h-12.6l-1.64-11.23-4.97 11.23h-11.32z" data-name="Path 424"/>
        <path d="M57 0L34.02 26.89h12.47c7.47-1.72-1.35-6.15-1.35-6.15s10.5 2.25 10.59 2.3c5.29 1.48.82 3.85.82 3.85H69.3L70.13 0zm.86 19.26H52.2l7.1-9.5z" data-name="Path 425"/>
        <path d="M126.45 0l-22.94 26.89H116c7.47-1.72-1.36-6.15-1.36-6.15s10.51 2.25 10.6 2.3c5.29 1.48.81 3.85.81 3.85h12.76L139.62 0zm.86 19.26h-5.66l7.1-9.5z" data-name="Path 426"/>
        <path d="M186.08 0L163.1 26.89h12.47c7.47-1.72-1.35-6.15-1.35-6.15s10.5 2.25 10.59 2.3c5.29 1.48.82 3.85.82 3.85h12.76L199.2 0zm.86 19.26h-5.66l7.1-9.5z" data-name="Path 427"/>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: "MainHeader"
};
</script>

<style scoped>
.main-header {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: transparent;
}

.main-header a {
  display: flex;
  align-items: center;
  height: 100%;
}

.main-header svg {
  height: 100%;
  width: auto;
}
</style>
